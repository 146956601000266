import React from "react";
import Header from "../components/Header";
import { useNavigation } from "../contexts/NavigationContext";

const Profits = () => {
  const { activeNavItem, handleNavItemClick } = useNavigation();

  return (
    <>
      <Header />

      <div className="my-10 flex flex-col">
        <div className="w-fit bg-[#000000b4] p-2 rounded-md grid grid-cols-2 gap-2 self-center sm:self-start sm:grid-cols-none sm:flex">
          <div
            className={`text-white p-2 rounded-sm text-[15px] cursor-pointer sm:w-fit sm:text-base ${
              activeNavItem === "Profits" ? "bg-[#26272a]" : ""
            }`}
            onClick={() => handleNavItemClick("Profits")}
          >
            Profits
          </div>

          <div
            className={`text-white p-2 rounded-sm text-[15px] cursor-pointer sm:w-fit sm:text-base ${
              activeNavItem === "Total 50/50" ? "bg-[#141517]" : ""
            }`}
            onClick={() => handleNavItemClick("Total 50/50")}
          >
            Total 50/50
          </div>

          <div
            className={`text-white p-2 rounded-sm text-[15px] cursor-pointer sm:w-fit sm:text-base ${
              activeNavItem === "Total Claim" ? "bg-[#141517]" : ""
            }`}
            onClick={() => handleNavItemClick("Total Claim")}
          >
            Total Claim
          </div>

          <div
            className={`text-white p-2 rounded-sm text-[15px] cursor-pointer sm:w-fit sm:text-base ${
              activeNavItem === "Total Compound" ? "bg-[#141517]" : ""
            }`}
            onClick={() => handleNavItemClick("Total Compound")}
          >
            Total Compound
          </div>
        </div>
      </div>
    </>
  );
};

export default Profits;
