import React, { useState } from "react";
import { FaMoneyBillAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { RxExit } from "react-icons/rx";
import Actions from "../components/Actions";
import Enlistement from "../components/Enlistement";
import Header from "../components/Header";
import Stats from "../components/Stats";

const Dashboard = ({ setIsModalOpen }) => {
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isPayoutModalOpen, setIsPayoutModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [payoutMode, setPayoutMode] = useState("");

  return (
    <>
      <Header />

      <div className="mt-20 mb-10 flex flex-col items-center gap-20 lg:flex-row lg:items-start lg:gap-12 1xl:gap-24">
        <Enlistement />

        <div className="flex flex-col gap-20 lg:flex-1">
          <Stats />

          <Actions
            setIsModalOpen={setIsModalOpen}
            setIsDepositModalOpen={setIsDepositModalOpen}
            setIsPayoutModalOpen={setIsPayoutModalOpen}
            setIsExportModalOpen={setIsExportModalOpen}
          />
        </div>
      </div>

      {/* Deposit Modal */}
      <div
        className={`w-full h-screen bg-[#000000cf] px-6 py-8 fixed left-0 top-0 overflow-y-auto ${
          isDepositModalOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        } transition-opacity duration-[400ms] ease-in-out`}
      >
        <div className="min-h-[calc(100vh_-_4rem)] flex items-center justify-center">
          <div className="relative bg-black p-6 pt-14 w-full max-w-[600px] border-2 border-white rounded-md">
            <IoCloseCircleOutline
              className="text-white text-3xl absolute top-2 right-3 cursor-pointer"
              onClick={() => {
                setIsModalOpen(false);
                setIsDepositModalOpen(false);
              }}
            />

            <div className="max-w-[360px] m-auto text-white text-[15px] sm:text-base">
              <h1 className="text-center text-2xl font-semibold">DEPOSIT</h1>

              <div className="mt-10">
                <div className="flex flex-col gap-2">
                  <label htmlFor="">Enter Deposit Value: (min $100 USDC)</label>

                  <div className="flex items-center gap-2 border rounded-md px-2.5 py-1.5">
                    <p>$</p>
                    <input
                      type="text"
                      className="bg-[transparent] outline-none"
                      placeholder="0"
                    />
                  </div>
                </div>

                <div className="my-8 px-6 flex flex-col gap-6">
                  <div className="flex flex-col gap-1.5">
                    <div className="flex items-center justify-between">
                      <p>Fees (15%):</p>
                      <p>$15.00</p>
                    </div>

                    <div className="flex items-center justify-between">
                      <p>Pool Value (85%):</p>
                      <p>$85.00</p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1.5">
                    <p>Final Pool Value:</p>
                    <div className="flex items-center justify-between">
                      <p>$0.00</p>
                      <p>-------{">"}</p>
                      <p className="text-green">$85.00</p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1.5">
                    <p>Tier Change:</p>
                    <div className="flex items-center justify-between">
                      <p>0</p>
                      <p>-------{">"}</p>
                      <p className="text-primaryYellow">0</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4 items-center ">
                <button
                  className="text-[#383427] bg-gradient-to-r from-primaryYellow to-secondaryYellow py-2 active:scale-[0.9] transition-all duration-500 rounded-lg min-w-[180px] justify-center font-bold flex items-center gap-3 disabled:opacity-50 disabled:active:scale-100"
                  onClick={() => {
                    setIsModalOpen(true);
                    setIsDepositModalOpen(true);
                  }}
                >
                  <FaMoneyBillAlt className="text-[22px]" />
                  Approve
                </button>

                <button
                  className="text-[#383427] bg-gradient-to-r from-primaryYellow to-secondaryYellow py-2 active:scale-[0.9] transition-all duration-500 rounded-lg min-w-[180px] justify-center font-bold flex items-center gap-3 disabled:opacity-50 disabled:active:scale-100"
                  onClick={() => {
                    setIsModalOpen(true);
                    setIsDepositModalOpen(true);
                  }}
                >
                  <FaMoneyBillAlt className="text-[22px]" />
                  Deposit
                </button>
              </div>

              <div className="w-[80%] h-[2px] bg-white m-auto my-8"></div>

              <button
                className="m-auto text-[#383427] bg-gradient-to-r from-primaryYellow to-secondaryYellow py-2 active:scale-[0.9] transition-all duration-500 rounded-lg min-w-[250px] justify-center font-bold flex items-center gap-3 disabled:opacity-50 disabled:active:scale-100"
                onClick={() => {
                  setIsModalOpen(true);
                  setIsDepositModalOpen(true);
                }}
              >
                <RxExit className="text-[22px]" />
                Buy With Credit Card
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Payout Mode Modal */}
      <div
        className={`w-full h-screen bg-[#000000cf] px-6 py-8 fixed left-0 top-0 overflow-y-auto ${
          isPayoutModalOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        } transition-opacity duration-[400ms] ease-in-out`}
      >
        <div className="min-h-[calc(100vh_-_4rem)] flex items-center justify-center">
          <div className="relative bg-black p-6 pt-14 w-full max-w-[500px] border-2 border-white rounded-md">
            <IoCloseCircleOutline
              className="text-white text-3xl absolute top-2 right-3 cursor-pointer"
              onClick={() => {
                setIsModalOpen(false);
                setIsPayoutModalOpen(false);
              }}
            />

            <div className="max-w-[17rem] m-auto text-white text-[15px] sm:text-base">
              <h1 className="text-center text-2xl font-semibold">
                PAYOUT MODE
              </h1>

              <div className="mt-8 mb-14 flex flex-col gap-8">
                <div class="radio" onClick={() => setPayoutMode("claim")}>
                  <input
                    type="radio"
                    checked={payoutMode === "claim" ? true : false}
                  />
                  <span>
                    <div>
                      <h1 className="text-[17px] font-semibold">Claim Mode</h1>

                      <p className="text-[15px] text-[#787F85]">
                        Claim all profits in next cycle with 10% fees
                      </p>
                    </div>
                  </span>
                </div>

                <div class="radio" onClick={() => setPayoutMode("50/50")}>
                  <input
                    type="radio"
                    checked={payoutMode === "50/50" ? true : false}
                  />
                  <span>
                    <div>
                      <h1 className="text-[17px] font-semibold">50/50 Mode</h1>

                      <p className="text-[15px] text-[#787F85]">
                        Claim half of profits with 10% fees & compound rest half
                      </p>
                    </div>
                  </span>
                </div>

                <div class="radio" onClick={() => setPayoutMode("compound")}>
                  <input
                    type="radio"
                    checked={payoutMode === "compound" ? true : false}
                  />
                  <span>
                    <div>
                      <h1 className="text-[17px] font-semibold">
                        Compound Mode
                      </h1>

                      <p className="text-[15px] text-[#787F85]">
                        Compound all profits to next cycle
                      </p>
                    </div>
                  </span>
                </div>
              </div>

              <button
                className="m-auto text-[#383427] bg-gradient-to-r from-primaryYellow to-secondaryYellow py-2 active:scale-[0.9] transition-all duration-500 rounded-lg min-w-[200px] justify-center font-bold flex items-center gap-3 disabled:opacity-50 disabled:active:scale-100"
                onClick={() => {
                  setIsModalOpen(true);
                  setIsDepositModalOpen(true);
                }}
              >
                <FiEdit className="text-[22px]" />
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Export Private Key Modal */}
      <div
        className={`w-full h-screen bg-[#000000cf] px-6 py-8 fixed left-0 top-0 overflow-y-auto ${
          isExportModalOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        } transition-opacity duration-[400ms] ease-in-out`}
      >
        <div className="min-h-[calc(100vh_-_4rem)] flex items-center justify-center">
          <div className="relative bg-black p-6 pt-14 w-full max-w-[500px] border-2 border-white rounded-md">
            <IoCloseCircleOutline
              className="text-white text-3xl absolute top-2 right-3 cursor-pointer"
              onClick={() => {
                setIsModalOpen(false);
                setIsExportModalOpen(false);
              }}
            />

            <div className="max-w-[360px] m-auto text-white text-[15px] sm:text-base">
              <h1 className="text-center text-2xl font-semibold uppercase">
                EXPORT PAPER PRIVATE KEY
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
