import React, { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import About from "./pages/About";
import Dashboard from "./pages/Dashboard";
import LandingPage from "./pages/LandingPage";
import Marketplace from "./pages/Marketplace";
import Profits from "./pages/Profits";
import Tiers from "./pages/Tiers";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div
      className={`bg-[url('./assets/images/bg.jpg')] bg-no-repeat bg-cover bg-fixed w-full ${
        isModalOpen ? "fixed" : ""
      }`}
    >
      <div className="bg-[#0000006e] w-full min-h-screen">
        <div className="flex justify-center">
          <div className="w-full max-w-[1500px] p-4 sm:p-6 lg:p-10 1xl:px-20">
            <Router>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route
                  path="/dashboard"
                  element={
                    <Dashboard
                      setIsModalOpen={setIsModalOpen}
                    />
                  }
                />
                <Route path="/tiers" element={<Tiers />} />
                <Route path="/profits" element={<Profits />} />
                <Route path="/marketplace" element={<Marketplace />} />
                <Route path="/about" element={<About />} />
              </Routes>
            </Router>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
