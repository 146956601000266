import React from "react";
import { BsInfoCircle } from "react-icons/bs";
import { FaMoneyBillAlt, FaMoneyCheckAlt } from "react-icons/fa";

const Actions = ({
  setIsModalOpen,
  setIsDepositModalOpen,
  setIsPayoutModalOpen,
  setIsExportModalOpen,
}) => {
  return (
    <div className="w-[calc(100vw_-_4rem)] max-w-[30rem]  lg:w-full lg:max-w-none px-8 py-8 rounded-[15px] border-gradient before:p-[2px] before:rounded-[15px] border-gradient bg-[#00000075]">
      <div className="absolute -top-7 left-1/2 -translate-x-1/2 z-10">
        <span class="banner z-20">
          <div class="content px-5 text-[20px] font-extrabold uppercase">
            <p className="bg-gradient-to-r from-primaryYellow to-secondaryYellow text-transparent bg-clip-text">
              Actions
            </p>

            <div className="border-t-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
            <div className="border-b-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
          </div>
        </span>

        <div className="w-[98%] h-[65%] bg-primaryYellow absolute left-1/2 -translate-x-1/2 bottom-[6px]"></div>
      </div>

      <div className="flex flex-col gap-6 vsm:px-6 sm:pl-10 lg:items-start lg:px-0">
        <div className="flex flex-col-reverse items-start gap-4 lg:flex-row lg:gap-8 lg:items-center">
          <button
            className="text-[#383427] bg-gradient-to-r from-primaryYellow to-secondaryYellow py-2 active:scale-[0.9] transition-all duration-500 rounded-lg min-w-[250px] justify-center font-bold flex items-center gap-3 disabled:opacity-50 disabled:active:scale-100"
            onClick={() => {
              setIsModalOpen(true);
              setIsDepositModalOpen(true);
            }}
          >
            <FaMoneyBillAlt className="text-[22px]" />
            Deposit
          </button>

          <div className="flex gap-2 items-center text-white font-medium">
            <BsInfoCircle className="min-w-[20px] min-h-[20px]" />
            <p>Buy NFT / Increase Pool</p>
          </div>
        </div>

        <div className="flex flex-col-reverse items-start gap-4 lg:flex-row lg:gap-8 lg:items-center">
          <button
            className="text-[#383427] bg-gradient-to-r from-primaryYellow to-secondaryYellow py-2 active:scale-[0.9] transition-all duration-500 rounded-lg min-w-[250px] justify-center font-bold flex items-center gap-3 disabled:opacity-50 disabled:active:scale-100"
            onClick={() => {
              setIsModalOpen(true);
              setIsPayoutModalOpen(true);
            }}
          >
            <FaMoneyCheckAlt className="text-[22px]" />
            Payout Mode
          </button>

          <div className="flex gap-2 items-center text-white font-medium">
            <BsInfoCircle className="min-w-[20px] min-h-[20px]" />
            <p>Change payout for next month</p>
          </div>
        </div>

        <div className="flex flex-col-reverse items-start gap-4 lg:flex-row lg:gap-8 lg:items-center">
          <button
            disabled
            className="text-[#383427] bg-gradient-to-r from-primaryYellow to-secondaryYellow py-2 active:scale-[0.9] transition-all duration-500 rounded-lg min-w-[250px] justify-center font-bold flex items-center gap-3 disabled:opacity-50 disabled:active:scale-100"
          >
            Claim
          </button>

          <div className="flex gap-2 items-center text-white font-medium">
            <BsInfoCircle className="min-w-[20px] min-h-[20px]" />
            <p>Claim profits</p>
          </div>
        </div>

        <div className="flex flex-col-reverse items-start gap-4 lg:flex-row lg:gap-8 lg:items-center">
          <button
            className="text-[#383427] bg-gradient-to-r from-primaryYellow to-secondaryYellow py-2 active:scale-[0.9] transition-all duration-500 rounded-lg min-w-[250px] justify-center font-bold flex items-center gap-3 disabled:opacity-50 disabled:active:scale-100"
            onClick={() => {
              setIsModalOpen(true);
              setIsExportModalOpen(true);
            }}
          >
            Export Paper Private Key
          </button>

          <div className="flex gap-2 items-center text-white font-medium">
            <BsInfoCircle className="min-w-[20px] min-h-[20px]" />
            <p>Export private key from paper wallet</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Actions;
