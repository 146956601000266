import React from "react";
import Image from "../assets/images/tier0.png";

const Enlistement = () => {
  return (
    <div className="w-fit px-8 pt-16 pb-8 rounded-[15px] border-gradient before:p-[2px] before:rounded-[15px] border-gradient bg-[#00000075]">
      <div className="absolute -top-7 left-1/2 -translate-x-1/2 z-10">
        <span class="banner z-20">
          <div class="content px-5 text-[20px] font-extrabold uppercase">
            <p className="bg-gradient-to-r from-primaryYellow to-secondaryYellow text-transparent bg-clip-text">
              Enlistement
            </p>

            <div className="border-t-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
            <div className="border-b-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
          </div>
        </span>

        <div className="w-[98%] h-[65%] bg-primaryYellow absolute left-1/2 -translate-x-1/2 bottom-[6px]"></div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="max-w-[15rem] border-gradient before:p-[5px] before:rounded-[15px] before:m-[-5px] 1xl:max-w-[18rem]">
          <img src={Image} alt="" className="w-full h-full rounded-[8px]" />
        </div>

        <div className="flex flex-col gap-2">
          <div className="text-white font-medium flex flex-col gap-1">
            <p>Tier 0</p>

            <p>
              Trading Pool : <span className="text-green">$0.00</span>
            </p>
          </div>

          <div className="bg-primaryYellowShadow relative h-[10px] w-full rounded-2xl">
            <div className="bg-primaryYellow absolute top-0 left-0 h-full w-[60%] rounded-2xl"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enlistement;
