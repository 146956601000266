import React from "react";
import Tier0 from "../assets/images/tier0.png";
import Tier1 from "../assets/images/tier1.png";
import Tier2 from "../assets/images/tier2.png";
import Tier3 from "../assets/images/tier3.png";
import Tier4 from "../assets/images/tier4.png";
import Tier5 from "../assets/images/tier5.png";
import Tier6 from "../assets/images/tier6.png";
import Header from "../components/Header";
import Tier from "../components/Tier";

const Tiers = () => {
  return (
    <>
      <Header />

      <div className="my-10 flex flex-col items-center">
        <h1 className="text-center text-3xl font-serif font-bold text-primaryYellow tracking-wider">
          TIER LIST
        </h1>

        <div className="mt-20 grid justify-center items-center gap-y-20 sm:grid-cols-2 sm:gap-x-16 md:grid-cols-3">
          <Tier
            title="Enlistment"
            imageSrc={Tier0}
            tierValue="0"
            tradingPoolValue="$0.00"
          />

          <Tier
            title="Wardog"
            imageSrc={Tier1}
            tierValue="I"
            tradingPoolValue="$1 - $849"
          />

          <Tier
            title="Warsoldier"
            imageSrc={Tier2}
            tierValue="II"
            tradingPoolValue="$850 - $4249"
          />

          <Tier
            title="Warhummer"
            imageSrc={Tier3}
            tierValue="III"
            tradingPoolValue="$4250 - $8499"
          />

          <Tier
            title="Wartank"
            imageSrc={Tier4}
            tierValue="IV"
            tradingPoolValue="$8500 - $21249"
          />

          <Tier
            title="Warhelo"
            imageSrc={Tier5}
            tierValue="V"
            tradingPoolValue="$21250 - $99999"
          />

          <Tier
            title="Warjet"
            imageSrc={Tier6}
            tierValue="VI"
            tradingPoolValue="$100k+"
          />
        </div>
      </div>
    </>
  );
};

export default Tiers;
