import React from "react";
import SwordsSvg from "../assets/images/swords.svg";
import WarfiLanding from "../assets/images/warfiLanding.png";

const LandingPage = () => {
  return (
    <div className="h-screen w-screen absolute top-0 left-0 lg:flex lg:flex-col lg:items-center lg:justify-center">
      <button class="absolute z-20 cursor-pointer right-[1rem] top-[1rem] font-bold text-[#FFE761] underline underline-offset-2 hover:underline lg:right-[5rem] lg:top-[2.5rem] lg:text-lg">
        Buy Warbot with Credit Card
      </button>

      <div className="flex flex-col items-center top-[5rem] absolute left-1/2 -translate-x-1/2 w-full vsm:max-w-[33rem] md:max-w-[42rem] lg:max-w-[59rem] lg:top-4 lg:static lg:translate-x-0">
        <img src={WarfiLanding} alt=""></img>

        <div className="flex flex-col items-center gap-8 lg:relative lg:bottom-14">
          <p class="text-white w-[14rem] text-center font-medium vsm:w-[21rem] vsm:text-[1.2rem] sm:w-max lg:text-[1.3rem]">
            Waging War on Defi for a Safer &amp; Better Experience
          </p>

          <button className="text-black bg-gradient-to-r from-primaryYellow to-secondaryYellow py-2 active:scale-[0.9] transition-all duration-500 rounded-lg min-w-[250px] justify-center font-bold flex items-center gap-3 disabled:opacity-50 disabled:active:scale-100">
            <img src={SwordsSvg} alt="" className="w-5" />
            Connect To Join Battle
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
