import React, { useState } from "react";
import { BsCart3, BsChevronDown, BsChevronLeft } from "react-icons/bs";

const TableSection = ({ title,  tableData, defaultVisible }) => {
    const [isTableVisible, setTableVisible] = useState(defaultVisible);

    const toggleTable = () => {
      setTableVisible((prevVisible) => !prevVisible);
    };

  return (
    <div className="border-b py-6 flex flex-col gap-4">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={toggleTable}
      >
        <h1 className="font-medium">{title}</h1>
        {isTableVisible ? (
          <BsChevronDown className="text-xl" />
        ) : (
          <BsChevronLeft className="text-xl" />
        )}
      </div>

      <table className={`w-full text-sm md:text-base ${isTableVisible ? "" : "hidden"}`}>
        <thead className="bg-[#16181ac9] text-[#888587]">
          <tr>
            <th className="w-[33.33%] text-center py-3 rounded-l-2xl">
              TOKEN ID
            </th>
            <th className="w-[33.33%] text-center py-3">VALUE</th>
            <th className="w-[33.33%] text-center py-3 rounded-r-2xl">
              ACTION
            </th>
          </tr>
        </thead>

        <tbody>
          {tableData.map((data, index) => (
            <tr key={index}>
              <td className="text-center py-4">{data.tokenId}</td>
              <td className="text-center py-4">{data.value}</td>
              <td className="flex justify-center py-4">
                <button
                  className="text-black bg-gradient-to-r from-primaryYellow to-secondaryYellow py-2 active:scale-[0.9] transition-all duration-500 rounded-lg min-w-[80px] md:min-w-[130px] justify-center font-bold flex items-center gap-2 md:gap-3 disabled:opacity-50 disabled:active:scale-100"
                  onClick={data.onButtonClick}
                >
                  <BsCart3 className="text-[19px] md:text-[22px]" />
                  Buy
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSection;
