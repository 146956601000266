import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Header from "../components/Header";
import TableSection from "../components/TableSection";

const Marketplace = () => {
  const tierOneTableData = [
    {
      tokenId: "18",
      value: "$500",
      onButtonClick: () => {},
    },
    {
      tokenId: "530",
      value: "$500",
      onButtonClick: () => {},
    },
    {
      tokenId: "624",
      value: "$500",
      onButtonClick: () => {},
    },
    {
      tokenId: "775",
      value: "$500",
      onButtonClick: () => {},
    },
    {
      tokenId: "947",
      value: "$500",
      onButtonClick: () => {},
    },
    {
      tokenId: "1028",
      value: "$500",
      onButtonClick: () => {},
    },
  ];

  const tierTwoTableData = [
    {
      tokenId: "19",
      value: "$750",
      onButtonClick: () => {},
    },
    {
      tokenId: "19",
      value: "$750",
      onButtonClick: () => {},
    },
    {
      tokenId: "19",
      value: "$750",
      onButtonClick: () => {},
    },
    {
      tokenId: "19",
      value: "$750",
      onButtonClick: () => {},
    },
  ];

  return (
    <>
      <Header />

      <div className="my-10 flex flex-col items-center">
        <h1 className="text-center text-3xl font-serif font-bold text-primaryYellow tracking-wider">
          MARKETPLACE
        </h1>

        <div className="mt-20 max-w-[50rem] w-full px-8 py-8 rounded-[15px] border-gradient before:p-[2px] before:rounded-[15px] border-gradient bg-[#00000075]">
          <div className="absolute -top-7 left-1/2 -translate-x-1/2 z-10">
            <span className="banner z-20">
              <div className="content px-5 text-[20px] font-extrabold uppercase">
                <p className="w-[12.2rem] bg-gradient-to-r from-primaryYellow to-secondaryYellow text-transparent bg-clip-text">
                  List Your Wallet
                </p>

                <div className="border-t-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
                <div className="border-b-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
              </div>
            </span>

            <div className="w-[98%] h-[65%] bg-primaryYellow absolute left-1/2 -translate-x-1/2 bottom-[6px]"></div>
          </div>

          <div className="flex flex-col items-center gap-6 md:flex-row md:justify-between md:items-end">
            <div className="w-full flex flex-col gap-2 text-white md:w-fit">
              <div className="flex items-center gap-4 justify-between md:gap-24">
                <p>Your Tier:</p>
                <p className="font-medium">I</p>
              </div>

              <div className="flex items-center gap-4 justify-between md:gap-24">
                <p>Tier Name:</p>
                <p className="font-medium">WARDOG</p>
              </div>

              <div className="flex items-center gap-4 justify-between md:gap-24">
                <p>Pool Share:</p>
                <p className="font-medium text-green">$90.10</p>
              </div>
            </div>

            <button className="text-black bg-gradient-to-r from-primaryYellow to-secondaryYellow py-2 active:scale-[0.9] transition-all duration-500 rounded-lg min-w-[220px] justify-center font-bold flex items-center gap-3 disabled:opacity-50 disabled:active:scale-100">
              <AiOutlinePlusCircle className="text-[25px]" />
              List Your Wallet
            </button>
          </div>
        </div>

        <div className="w-[85%] h-[2px] bg-white self-center my-14"></div>

        <div className="w-full text-white lg:max-w-none px-6 rounded-[15px] border-gradient before:p-[2px] before:rounded-[15px] border-gradient bg-[#00000075]">
          <TableSection
            title="Tier I"
            tableData={tierOneTableData}
            defaultVisible={true}
          />

          <TableSection
            title="Tier II"
            tableData={tierTwoTableData}
            defaultVisible={false}
          />
        </div>
      </div>
    </>
  );
};

export default Marketplace;
