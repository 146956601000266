import React from "react";
import Andrew from "../assets/images/andrew.png";
import Jason from "../assets/images/jason.png";
import Header from "../components/Header";

const About = () => {
  return (
    <>
      <Header />

      <div className="mt-20 mb-10 flex flex-col items-center gap-20 lg:gap-24">
        <div className="w-full px-8 py-12 pb-8 rounded-[15px] border-gradient before:p-[2px] before:rounded-[15px] border-gradient bg-[#00000075] md:self-center md:max-w-[55rem]">
          <div className="absolute -top-7 left-1/2 -translate-x-1/2 z-10">
            <span class="banner w-[13.5rem] z-20">
              <div class="content px-5 text-[20px] font-extrabold uppercase">
                <p className="bg-gradient-to-r from-primaryYellow to-secondaryYellow text-transparent bg-clip-text">
                  What Is WarFi?
                </p>

                <div className="border-t-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
                <div className="border-b-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
              </div>
            </span>

            <div className="w-[98%] h-[65%] bg-primaryYellow absolute left-1/2 -translate-x-1/2 bottom-[6px]"></div>
          </div>

          <div className="flex flex-col gap-4 text-white leading-7">
            <p>
              WarFi was created to wage war on Defi and help remove all of the
              bad actors in this space by providing a safe and secure place for
              users to build real wealth, without worrying about being rugged,
              taken advantage of, or being scammed of their hard earned money.
            </p>

            <p>
              WarFi offers monthly returns to its users, which have averaged 9%
              per month over the last 17 months, without any red months since
              its inception. This is done through our exclusive partnership with
              Shiny Jackal and his trading bot service.
            </p>
          </div>
        </div>

        <div className="w-full px-8 py-12 pb-8 rounded-[15px] border-gradient before:p-[2px] before:rounded-[15px] border-gradient bg-[#00000075] md:self-center">
          <div className="absolute -top-7 left-1/2 -translate-x-1/2 z-10">
            <span class="banner w-[13.5rem] z-20">
              <div class="content px-5 text-[20px] font-extrabold uppercase">
                <p className="bg-gradient-to-r from-primaryYellow to-secondaryYellow text-transparent bg-clip-text">
                  Meet The Team
                </p>

                <div className="border-t-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
                <div className="border-b-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
              </div>
            </span>

            <div className="w-[98%] h-[65%] bg-primaryYellow absolute left-1/2 -translate-x-1/2 bottom-[6px]"></div>
          </div>

          <div className="flex flex-col gap-16 lg:flex-row">
            <div className="flex flex-col gap-5 text-white leading-7 lg:gap-6">
              <img
                src={Jason}
                alt=""
                className="w-48 self-center rounded-full border-4 border-white lg:w-[16rem]"
              />

              <h1 className="text-center text-xl font-bold uppercase">
                Jason Baxter - CEO & Founder
              </h1>

              <p className="text-justify">
                With over 15+ years in finance, sales and director of
                partnerships experience, as well as being an OG in the
                Crypto/Defi space, Jason brings all of his years of his skills
                and learning to create and build a real business built to
                succeed and make a difference in the Defi world. He is the
                visionary and the ‘brain’ behind WarFi with the drive and
                passion to create one of, if not, the most successful and
                sustainable project in Defi, providing all users with real
                opportunities to grow their wealth safely and with confidence
                for many years to come.
              </p>
            </div>

            <div className="flex flex-col gap-5 text-white leading-7 lg:gap-6">
              <img
                src={Andrew}
                alt=""
                className="w-48 self-center rounded-full border-4 border-white lg:w-[16rem]"
              />

              <h1 className="text-center text-xl font-bold uppercase">
                Andrew Ponce a.k.a CoachAndrew87 - COO & Founder
              </h1>

              <p className="text-justify">
                Andrew brings 15+ years of experience of running his own
                businesses from managing projects, people, teams, finances and
                graphic design. He has always been a hard worker and never been
                afraid to put in the work and get things done. He is the perfect
                support to the visionary behind WarFI (Shaq to Kobe), who brings
                clarity, planning and execution to the ideas and the mission
                that Jason brings to WarFi, to ensure things get done the right
                way and that everyone in the community is taken care of.
              </p>
            </div>
          </div>
        </div>

        <div className="relative mt-5 text-white leading-7 flex flex-col gap-6 lg:gap-8 lg:mt-14">
          <div className="w-[35%] h-[2px] bg-white self-center absolute -top-12 lg:-top-20"></div>

          <p>
            WarFi has grown and gone from strength to strength building in
            numbers of happy, satisfied users; expanding its reach and making
            the name WarFi known throughout all of Defi. We have other big
            projects investing their treasuries into our services to help them
            grow with some projects investing upto $1million into WarFi. We have
            become one of the safest and most trusted names in Defi - and this
            is only the beginning!
          </p>

          <p>
            We've also expanded our team to include core individuals that help
            make WarFi a true force to be witnessed in this space, catapulting
            this business to the next level.
          </p>

          <p>
            For more information on what WarFi has to offer as well as
            information on our full core team, please see our whitepaper linked{" "}
            {""}
            <a
              href="https://warfi.gitbook.io/warfi-p2e/"
              className="underline text-primaryYellow"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
