import React, { createContext, useContext, useState } from "react";

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const [activeNavItem, setActiveNavItem] = useState("Dashboard");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavItemClick = (item) => {
    setActiveNavItem(item);
    setIsMenuOpen(false);
  };

  const handleMenuButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <NavigationContext.Provider
      value={{
        activeNavItem,
        isMenuOpen,
        handleNavItemClick,
        handleMenuButtonClick,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  return useContext(NavigationContext);
};
