import React from "react";

const Stats = () => {
  return (
    <div className="w-[calc(100vw_-_4rem)] max-w-[30rem] lg:w-full lg:max-w-none px-8 py-8 rounded-[15px] border-gradient before:p-[2px] before:rounded-[15px] border-gradient bg-[#00000075]">
      <div className="absolute -top-7 left-1/2 -translate-x-1/2 z-10">
        <span class="banner z-20">
          <div class="content px-5 text-[20px] font-extrabold uppercase">
            <p className="bg-gradient-to-r from-primaryYellow to-secondaryYellow text-transparent bg-clip-text">
              Stats
            </p>

            <div className="border-t-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
            <div className="border-b-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
          </div>
        </span>

        <div className="w-[98%] h-[65%] bg-primaryYellow absolute left-1/2 -translate-x-1/2 bottom-[6px]"></div>
      </div>

      <div className="">
        <div className="grid gap-6 w-full text-white text-[17px] lg:grid-cols-2 lg:gap-x-16 1xl:gap-x-20">
          <div className="flex items-center gap-4 justify-between">
            <p>Until Next Tier</p>
            <p className="font-medium text-primaryYellow">$0</p>
          </div>

          <div className="flex items-center gap-4 justify-between">
            <p>Payout Mode</p>
            <p className="font-medium text-primaryYellow">Compound</p>
          </div>

          <div className="flex items-center gap-4 justify-between">
            <p>Until Next Tier</p>
            <p className="font-medium text-primaryYellow">$0</p>
          </div>

          <div className="flex items-center gap-4 justify-between">
            <p>Claimable Profits</p>
            <p className="font-medium text-green">$0</p>
          </div>

          <div className="flex items-center gap-4 justify-between">
            <p>Until Next Tier</p>
            <p className="font-medium text-primaryYellow">$0</p>
          </div>

          <div className="flex items-center gap-4 justify-between">
            <p>Last Cycle Profit</p>
            <p className="font-medium text-green">3.24%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
