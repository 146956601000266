import React from "react";
import { RiCloseFill, RiMenu3Fill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import WarFiLogo from "../assets/images/Warfi.png";
import { useNavigation } from "../contexts/NavigationContext";

const Header = () => {
  const { isMenuOpen, handleNavItemClick, handleMenuButtonClick } =
    useNavigation();

  const navigationItems = [
    "Dashboard",
    "Tiers",
    "Profits",
    "Marketplace",
    "About",
  ];

  const location = useLocation();
  const activePath = location.pathname.split("/")[1];

  const handleNavClick = (item) => {
    handleNavItemClick(item);
    handleMenuButtonClick();
  };

  return (
    <header
      className={`flex items-center justify-between relative ${
        isMenuOpen ? "mb-[22rem] lg:mb-0" : "lg:mb-0"
      }`}
    >
      <div>
        <img src={WarFiLogo} alt="" className="w-[5.5rem] lg:w-32" />
      </div>

      <nav
        className={`absolute w-full top-16 left-0 lg:block lg:relative lg:top-0 lg:left-0 lg:w-fit ${
          isMenuOpen ? "block slide-in lg:animate-none" : "hidden"
        }`}
      >
        <ul className="flex flex-col gap-2 items-start lg:flex-row lg:items-center lg:gap-8 1xl:gap-10">
          {navigationItems.map((item) => (
            <Link
              to={`/${item.toLowerCase()}`}
              key={item}
              className={`hover:bg-[#0000005d] cursor-pointer transition-all duration-300 w-full px-3 py-1 rounded-lg lg:hover:bg-[transparent] lg:bg-[transparent] lg:w-fit lg:p-0 ${
                activePath === item.toLowerCase()
                  ? "bg-[#524a1fcf] hover:bg-[#524a1fcf]"
                  : ""
              }`}
              onClick={() => handleNavClick(item)}
            >
              <div
                className={`text-lg transition duration-200 lg:bg-gradient-to-r from-primaryYellow to-secondaryYellow lg:text-transparent bg-clip-text lg:font-bold lg:text-lg ${
                  activePath === item.toLowerCase()
                    ? "font-medium text-primaryYellow text-shadow lg:text-transparent lg:text-opacity-0"
                    : "text-white lg:text-opacity-0"
                }`}
              >
                {item}
              </div>
            </Link>
          ))}

          <button className="button-87 mt-2 btn-gradient hover:bg-right active:scale-[0.9] px-10 py-1.5 rounded-full border-2 transition-all duration-500 border-[#e3bb6b] lg:mt-0 lg:px-8 1xl:px-10">
            <p className="bg-gradient-to-r from-primaryYellow to-secondaryYellow text-transparent bg-clip-text text-[19px] font-bold">
              Connect
            </p>
          </button>
        </ul>
      </nav>

      <button
        className={`text-white border-2 border-transparent hover:border-white focus:border-white transition duration-200 p-1.5 rounded-lg lg:hidden`}
        onClick={handleMenuButtonClick}
      >
        {isMenuOpen ? (
          <RiCloseFill className="text-2xl" />
        ) : (
          <RiMenu3Fill className="text-2xl" />
        )}
      </button>
    </header>
  );
};

export default Header;
