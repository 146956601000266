import React from "react";

const Tier = (props) => {
  const { title, imageSrc, tierValue, tradingPoolValue } = props;

  return (
    <div className="w-fit px-8 pt-12 pb-8 rounded-[15px] border-gradient before:p-[2px] before:rounded-[15px] border-gradient bg-[#00000075] sm:pt-16">
      <div className="absolute -top-7 left-1/2 -translate-x-1/2 z-10">
        <span className="banner z-20">
          <div className="content px-5 text-[20px] font-extrabold uppercase">
            <p className="bg-gradient-to-r from-primaryYellow to-secondaryYellow text-transparent bg-clip-text">
              {title}
            </p>

            <div className="border-t-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
            <div className="border-b-2 border-primaryYellow absolute top-0 left-0 w-full h-full"></div>
          </div>
        </span>

        <div className="w-[98%] h-[65%] bg-primaryYellow absolute left-1/2 -translate-x-1/2 bottom-[6px]"></div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="max-w-[15rem] border-gradient before:p-[5px] before:rounded-[15px] before:m-[-5px] 1xl:max-w-[18rem]">
          <img src={imageSrc} alt="" className="w-full h-full rounded-[8px]" />
        </div>

        <div className="flex flex-col gap-2">
          <div className="text-white font-medium flex flex-col gap-1">
            <p>Tier {tierValue}</p>

            <p>
              Trading Pool :{" "}
              <span className="text-green">{tradingPoolValue}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tier;
